
import { Vue, Component } from "vue-property-decorator";
import {
  IPatientProfileUpdate,
  IUserProfileResponsibleNurses,
  IVisitProfile,
} from "@/interfaces"; //TODO add Woundprofile
import {
  readPatient,
  readPatientID,
  readQRPatient,
  readBreeds,
  readVisits,
  readVisitsQuest,
  readLanguage,
  readEditHorse,
} from "@/store/admin/getters";
import { readHasAdminAccess } from "@/store/main/getters";
import {
  commitSetPatientID,
  commitSwitchLanguage,
} from "@/store/admin/mutations";
import {
  dispatchGetPatients,
  dispatchGetQRPatient,
  dispatchUpdatePatient,
  dispatchGetVisits,
  dispatchGetVisitsQuest,
} from "@/store/admin/actions";
import { jsPDF } from "jspdf";
import { api } from "@/api";
import { readToken, readUserProfile } from "@/store/main/getters";
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { Console } from "console";

@Component({
  components: {
    'my-upload': myUpload
  },
})
export default class PatientOverview extends Vue {
  public search = "";
  public searchNurses = "";

  public nurses: IUserProfileResponsibleNurses[] = [];
  public nursesNew: IUserProfileResponsibleNurses[] = [];
  public nurseParse: String[] = [];
  public show: boolean = false;
  public nurse_select: number = -1;

  public dialog = false;
  public dialogInfo = false;
  public dialogDelete = false;
  public dialogDeleteHoove = false;

  public b64: string = "";

  //for the editPatient
  public editable = false;
  public birthday: Date = new Date(1900, 1, 1);
  public birthplace = "";
  public nationality = "";
  public firstname = "";
  public lastname = "";
  public gender = "";
  public city = "";
  public postcode = 0;
  public road = "";
  public house_number = "";
  public genders = ["Stallion", "Filly/Mare", "Gelding"];
  public genders_german = ["Hengst", "Stute", "Wallach"];
  public dialogQR = false;
  public breed: String = "";


  public mountPic: boolean = false;

  public dummy = [
    { firstname: "Barabara", lastname: "Fitch", id: 11 },
    { firstname: "Jo", lastname: "Weihndoc", id: 12 },
  ];

  public headers = [
    {
      // text: "Name",
      text: "",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      // text: "ID ",
      text: "",
      sortable: false,
      value: "id",
      align: "left",
    },
    // {
    //   text: "ICD",
    //   sortable: false,
    //   value: "icd",
    //   align: "left",
    // },
  ];

  public headersVisit = [
    {
      text: "Date",
      sortable: true,
      value: "date",
      align: "left",
    },
    {
      text: "ID ",
      sortable: false,
      value: "id",
      align: "left",
    },
  ];

  public headersVisit_german = [
    {
      text: "Datum",
      sortable: true,
      value: "date",
      align: "left",
    },
    {
      text: "ID ",
      sortable: false,
      value: "id",
      align: "left",
    },
  ];

  public headersNurses = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
  ];

  showWoundCreate() {}
  //gets the patient from the patients by the id from the admin state
  get patient() {
    return readPatient(this.$store)(+this.$router.currentRoute.params.id);
  }

  get wounds() {
    return this.patient!.wounds;
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get language() {
    return readLanguage(this.$store);
  }

  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if (this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if (!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public editOrNot() {
    return !this.hasAdminAccess;
  }

  get editableBool() {
    return !this.editable;
  }

  public getQR() {
    return "data:image/jpeg;base64," + readQRPatient(this.$store);
  }
  //For imageCropUpload----------------
  public showCropper = false;
  public set = false;
  public imgDataUrl: string = '';
  public params = {
    token: '123456789',
    name: 'avatar'
  };
  public headersCropper = {
    smail: '*_~'
  }

  public de = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Dieser Browser wird nicht unterstützt, bitte benutzen sie IE10+ oder vergleichbare.',
        success: 'Hochladen erfolgreich',
        fail: 'Hochladen fehlgeschlagen',
        preview: 'Vorschau',
        btn: {
          off: 'Abbrechen',
          close: 'Schließen',
          back: 'Zurück',
          save: 'Sichern'
        },
        error: {
          onlyImg: 'Nur Bilder',
          outOfSize: 'Bild ist zu groß. Limit ist: ',
          lowestPx: 'Bilder Größe ist zu gering. Mindestgröße ist: '
        }
  }

  public en = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Image\'s size is too low. Expected at least: '
        }
  }

  get text() {
    if(this.language) {
      return this.en;
    } else return this.de;
  }

  public toggleShow() {
    this.showCropper = !this.showCropper;
  }

	cropSuccess(imgDataUrl, field){
    this.set = true;
		console.log('-------- crop success --------');
		this.imgDataUrl = imgDataUrl;
	}

  cropUploadSuccess(jsonData, field){
		console.log('-------- upload success --------');
		console.log(jsonData);
		console.log('field: ' + field);
	}

  cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
	}

  private b64Cleaner(b64:string) {
    let newA: String = b64.replace(
        "dataimage/pngbase64",
        "data:image/png;base64,"
      );
      let newB: String = newA.replace(
        "dataimage/jpegbase64",
        "data:image/jpeg;base64,"
      );
      //Removing padding from backend
      let newC = newB.replace(
        "=",
        ""
      )
      return newC;
  }

  //---------------------------------------


  public patientInfo() {
    this.dialogInfo = true;
  }

  public setPatientInfo() {
    if (this.patient?.firstname) {
      this.firstname = this.patient.firstname;
    }
    if (this.patient?.lastname) {
      this.lastname = this.patient.lastname;
    }
    if (this.patient?.nationality) {
      this.nationality = this.patient.nationality;
    }
    if (this.patient?.gender) {
      this.gender = this.patient.gender;
    }
    if (this.patient?.road) {
      this.road = this.patient.road;
    }
    if (this.patient?.city) {
      this.city = this.patient.city;
    }
    if (this.patient?.postcode) {
      this.postcode = this.patient.postcode;
    }
    if (this.patient?.house_number) {
      this.house_number = this.patient.house_number;
    }
    if (this.patient?.birthplace) {
      this.birthplace = this.patient.birthplace;
    }
    if (this.patient?.birthday) {
      this.birthday = this.patient.birthday;
    }
    if (this.patient?.breed) {
      this.breed = this.patient.breed;
    }
  }

  public async saveNurse() {
    await api
      .saveNurseToPatient(
        readToken(this.$store),
        this.patient!.id,
        this.nurse_select
      )
      .catch((error) => console.log(error));
    this.$router.push({name: 'main-horse-overview', params: {id: this.$router.currentRoute.params.id}});

    this.dialog = false;
    this.nurse_select = -1;
    this.updateNurseslist();
  }

  get disableNurse() {
    if (this.nurse_select != -1) {
      return false;
    }
    return true;
  }

  public download() {
    this.$router.push({name: 'main-horse-visit-all', params: {id: this.$router.currentRoute.params.id}});
  }

  public getFirstLetter() {
    var arr = Array.from(this.firstname);
    if (arr[0]) {
      return arr[0];
    }
    return "";
  }
  public async updateNurseslist() {
    await api
      .getNursesForPatients(readToken(this.$store), this.patient!.id)
      .then((response) => (this.nurses = response.data))
      .catch((error) => console.log(error));
    await api
      .getNotResponsibleNursesForPatients(
        readToken(this.$store),
        this.patient!.id
      )
      .then((response) => (this.nursesNew = response.data))
      .catch((error) => console.log(error));
  }

  public async deleteNurse(idNurse: number) {
    await api.deleteResponsibleNurse(
      readToken(this.$store),
      readPatientID(this.$store),
      idNurse
    );
    this.updateNurseslist();
  }
  //
  get namePatient() {
    if (this.patient) {
      return this.patient.firstname + " " + this.patient.lastname;
    } else {
      return "----";
    }
  }

  get breeds() {
    return readBreeds(this.$store);
  }

  get visits() {
    return readVisits(this.$store);
  }

  get quests() {
    return readVisitsQuest(this.$store);
  }

  get patientAdress() {
    var adress = "";
    if (this.patient?.road) {
      adress += this.patient.road + " ";
    }
    if (this.patient?.house_number) {
      adress += this.patient.house_number.toString();
    }
    adress += ", ";
    if (this.patient?.postcode) {
      adress += this.patient.postcode + " ";
    }
    if (this.patient?.city) {
      adress += this.patient.city;
    }
    return adress;
  }

  public async deleteWound(id: number) {
    await api.deleteWound(readToken(this.$store), id);
    dispatchGetPatients(this.$store);
    readPatient(this.$store)(+this.$router.currentRoute.params.id);
  }

  public getDate(date: string) {
    var d = new Date(date);
    return d.toLocaleString();
  }

  //gets the resonsible nurses at the beginning
  public async mounted() {
    dispatchGetQRPatient(this.$store, +this.$router.currentRoute.params.id);
    commitSetPatientID(this.$store, +this.$router.currentRoute.params.id);
    //dispatchGetPatients(this.$store);
    await api
      .getNursesForPatients(readToken(this.$store), this.patient!.id)
      .then((response) => (this.nurses = response.data))
      .catch((error) => console.log(error));
    await api
      .getNotResponsibleNursesForPatients(
        readToken(this.$store),
        this.patient!.id
      )
      .then((response) => (this.nursesNew = response.data))
      .catch((error) => console.log(error));
    var responseb64: string = "";
    await api
      .getProfilePic(
        readToken(this.$store),
        this.patient!.id
      )
      .then((response) => (responseb64= response.data))
      .catch((error) => console.log(error));
    this.imgDataUrl = this.b64Cleaner(responseb64);
    dispatchGetVisits(this.$store, this.patient!.id);
    dispatchGetVisitsQuest(this.$store, this.patient!.id);
    this.dialogInfo = readEditHorse(this.$store);
    this.setPatientInfo();
  }

  public downloadWounds() {
    var pdf = new jsPDF();
    var textPDF = "";
    this.wounds.forEach((element) => (textPDF += element + "" + +"\n"));
    //pdf.addImage()
    pdf.text(textPDF, 10, 10);
    pdf.save("users.pdf");
  }

  public beforeDestroy() {
    if(this.set) {
      this.savePatient();
    }
  }
  public async savePatient() {
    var updatePatient: IPatientProfileUpdate = {
      firstname: this.firstname,
      lastname: this.lastname,
    };
    if (this.gender != this.patient?.gender) {
      updatePatient.gender = this.gender;
    }
    if (this.road != this.patient?.road) {
      updatePatient.road = this.road;
    }
    if (this.house_number != this.patient?.house_number) {
      updatePatient.house_number = this.house_number;
    }
    if (this.postcode != this.patient?.postcode && this.postcode != 0) {
      updatePatient.postcode = this.postcode;
    }
    if (this.city != this.patient?.city) {
      updatePatient.city = this.city;
    }
    if (this.birthplace != this.patient?.birthplace) {
      updatePatient.birthplace = this.birthplace;
    }
    if (this.birthday.toString() != new Date(1900, 1, 1).toString()) {
      if (this.birthday.toString() != this.patient?.birthday.toString())
        updatePatient.birthday = this.birthday;
    }
    if (this.nationality != this.patient?.nationality) {
      updatePatient.nationality = this.nationality;
    }
    if (this.breed != this.patient?.breed) {
      updatePatient.breed = this.breed;
    }
    if (this.imgDataUrl && this.set) {
        let b64 = this.imgDataUrl;
        console.log(b64)
        try {
          await dispatchUpdatePatient(this.$store, {
            id: Number(this.patient?.id),
            patient: updatePatient,
            file: b64 as string,
          });
        } catch (e) {
          
        }
      } else {
        await dispatchUpdatePatient(this.$store, {
            id: Number(this.patient?.id),
            patient: updatePatient,
            file: "null",
        });
      }
    await dispatchGetPatients(this.$store);
    this.dialogInfo = false;
    this.editable = false;
  }

  public deletePatientIni() {
    this.dialogDelete = true;
  }

  public deleteHoove() {
    this.dialogDeleteHoove = true;
  }

  public no() {
    this.dialogDelete = false;
  }

  public noHoove() {
    this.dialogDeleteHoove = false;
  }

  public async deletePatient() {
    await api
      .deletePatient(readToken(this.$store), Number(this.$route.params.id))
      .catch((error) => console.log(error));
      const user = readUserProfile(this.$store)
      if (user?.is_superuser) {
        this.$router.push("/main/horse/users/all");
      } else {
        this.$router.push("/main/dashboard");
      }
  }


  public editHorse() {
    this.dialogInfo = true;
    this.editable = true;
  }

  public downloadQR() {
    var pdf = new jsPDF();
    var textPDF = "";
    pdf.setFontSize(25);
    pdf.text("QR Code for Scanning Patient", 43, 10);
    pdf.setFontSize(13);
    textPDF = "Name: ";
    if (this.patient) {
      if (this.patient.firstname) {
        textPDF += this.patient.firstname + " ";
      }
      if (this.patient.lastname) {
        textPDF += this.patient.lastname;
      }
    }
    pdf.text(textPDF, 10, 20);
    textPDF = "Adress: " + this.patientAdress;
    pdf.text(textPDF, 10, 27);
    pdf.addImage(this.getQR(), "JPEG", 3, 40, 200, 200);
    pdf.save("qrcode_patient_" + this.patient?.id);
  }

  public downloadPatient() {
    const pdf = new jsPDF();
    const pdfName = "patient_" + this.patient!.id + ".pdf";
    // var nurseText = "";
    // this.nurses.forEach(
    //   (element) =>
    //     (nurseText +=
    //       element.firstname +
    //       " " +
    //       element.lastname +
    //       "(ID:" +
    //       element.id +
    //       ");  ")
    // );
    // var textPDF =
    //   "Name: " +
    //   this.patient!.firstname +
    //   " " +
    //   this.patient!.lastname +
    //   "(ID: " +
    //   this.patient!.id +
    //   ")\n" +
    //   "Responsible Nurse(s): " +
    //   nurseText;
    // //pdf.addImage()
    // pdf.text(textPDF, 20, 20);var elementHTML = $('#contnet').html();

    // // pdf.html(html);
    // var img = new Image();
    // img.src = "../../images/logo.png";
    // pdf.addImage(img, "PNG", 14, 40, 450, 450);
    // pdf.save(pdfName);
  }

}
